// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAIs7t3apx-9arrc2SwuY5gpJZfdnfH2AE",
  authDomain: "capro-ashin.firebaseapp.com",
  projectId: "capro-ashin",
  storageBucket: "capro-ashin.appspot.com",
  messagingSenderId: "88020176173",
  appId: "1:88020176173:web:239bb2b93243343142d7a4",
  measurementId: "G-MS3DEGNTDC",
  databaseURL:
    "https://capro-ashin-default-rtdb.asia-southeast1.firebasedatabase.app/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaEnterpriseProvider(
//     "6LdpwjMmAAAAAP60oS2Cwui5M99VKdXd9KzMOPqp"
//   ),
//   isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
// });

const auth = getAuth(app);
const db = getDatabase(app);
const analytics = getAnalytics(app);
export { auth, db };

export const COMPANY_NAME = ""; // "JVA PRIVATE LIMITED";
